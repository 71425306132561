import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useEffect, useState } from 'react'
import styles from './Home.module.css'
// import Footer from 'components/Global/Layout/Footer/Footer'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import AboutUs from 'components/Home/AboutUs/AboutUs'
import BeingInControl from 'components/Home/BeingInControl/BeingInControl'
import Header from 'components/Home/Header/Header'
function Home() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [activeSection ,setActiveSection] = useState(1)
  const [isPageScrolling , setIsPageScrolling] =useState(false)

  useEffect(()=>{
    let stopExecuteOther = false
    let scrollEvent = (e:any)=>{
      if(e.deltaY <30 &&e.deltaY>-30) return 
      
        if(e.deltaY > 0){
          if(!stopExecuteOther){
            stopExecuteOther=true
            setTimeout(()=>{
              stopExecuteOther=false
            },1000)
            setActiveSection(prevVal=>{
              return prevVal==4 ?prevVal :prevVal+1
            })
          }
        }else{
          if(!stopExecuteOther){
            stopExecuteOther=true
            setTimeout(()=>{
              stopExecuteOther=false
            },1000)
            setActiveSection(prevVal=>{
              return prevVal==1 ?prevVal :prevVal-1
            })
          }
        }
    }
    window.addEventListener('mousewheel',scrollEvent)
    return ()=> window.removeEventListener('mousewheel',scrollEvent)
  },[])
  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
      <div className={`${styles['home']} js-home`}>
        <ul className={styles['home__navigate-wrapper-list']}>
          <li className={styles['home__navigate-wrapper-item']}>
            <button className={`${styles['home__navigate-wrapper-link']} ${activeSection==1 ?styles['home__navigate-wrapper-link--active']:''}`}
            onClick={()=>{setActiveSection(1)}}></button>
          </li>
          <li className={styles['home__navigate-wrapper-item']}>
            <button className={`${styles['home__navigate-wrapper-link']} ${activeSection==2 ?styles['home__navigate-wrapper-link--active']:''}`}
            onClick={()=>{setActiveSection(2)}}></button>
          </li>
          <li className={styles['home__navigate-wrapper-item']}>
            <button className={`${styles['home__navigate-wrapper-link']} ${activeSection==3 ?styles['home__navigate-wrapper-link--active']:''}`}
            onClick={()=>{setActiveSection(3)}}></button>
          </li>
          {/* <li className={styles['home__navigate-wrapper-item']}>
            <button className={`${styles['home__navigate-wrapper-link']} ${activeSection==4 ?styles['home__navigate-wrapper-link--active']:''}`}
            onClick={()=>{setActiveSection(4)}}></button>
          </li> */}
          {/* <li className={styles['home__navigate-wrapper-item']}>
            <button className={`${styles['home__navigate-wrapper-link']} ${activeSection==5 ?styles['home__navigate-wrapper-link--active']:''}`}
            onClick={()=>{setActiveSection(5)}}></button>
          </li> */}
        </ul>
        {/* <MetaTags>
              <title>Pavilion</title>
        </MetaTags> */}
        {/* {isPageLoading&&<Loading/>} */}
        <div className={`${styles['home__section-wrapper']} ${styles['home__section-wrapper--index-1']} 
        ${activeSection >1 ?styles['home__section-wrapper--prev']:''}
        ${activeSection==1 ?styles['home__section-wrapper--active']:''}`}>
          <Header/>
        </div>
        <div className={`${styles['home__section-wrapper']} ${styles['home__section-wrapper--index-2']} 
        ${activeSection >2 ?styles['home__section-wrapper--prev']:''}
        ${activeSection <2 ?styles['home__section-wrapper--next']:''}
        ${activeSection==2 ?styles['home__section-wrapper--active']:''}`}>
          <AboutUs/>
        </div>
        {/* <div className={`${styles['home__section-wrapper']} ${styles['home__section-wrapper--index-3']} 
        ${activeSection >3 ?styles['home__section-wrapper--prev']:''}
        ${activeSection <3 ?styles['home__section-wrapper--next']:''}
        ${activeSection==3 ?styles['home__section-wrapper--active']:''}`}>
          <BeingInControl/>
        </div> */}
        {/* <div className={`${styles['home__section-wrapper']} ${styles['home__section-wrapper--index-4']} 
        ${activeSection >4 ?styles['home__section-wrapper--prev']:''}
        ${activeSection <4 ?styles['home__section-wrapper--next']:''}
        ${activeSection ==4 ?styles['home__section-wrapper--active']:''}`}>
          <Blogs/>
        </div> */}
        <div className={`${styles['home__section-wrapper']} ${styles['home__section-wrapper--index-4']} 
        ${activeSection <3 ?styles['home__section-wrapper--next']:''}
        ${activeSection==3 ?styles['home__section-wrapper--active']:''}`}>
          <ContactUs/>
        </div>
        {/* <AboutUs/>
        <BeingInControl/> */}
        {/* <NavBar/> */}
        {/* <Footer/> */}
      </div>
    </motion.div>
    </>
  )
}

export default Home