import styles from './SwiperAboutUs.module.css'
import { useTranslation } from 'react-i18next'
import blogCardImage from 'assets/imgs/blogCardImage.jpg'
import {ReactComponent as CalenderIcon} from 'assets/icons/calenderIcon.svg'
import {ReactComponent as LocationIcon} from 'assets/icons/locationIcon.svg'
import { Link } from 'react-router-dom'
import { TeamMemberType } from 'components/Home/AboutUs/AboutUs'
// type ComponentCustomPropsTypes={
//     title?:string
// }
function SwiperAboutUs(props:TeamMemberType) {
    const {title,image,description}= props
    const {t} =useTranslation()
    
    return (
        <div className={styles['swiper-about-us']}>
            <div className={styles['swiper-about-us__wrapper']}>
                <img src={image} className={styles['swiper-about-us__image']} alt='about us Team Member image'/>
            </div>
            <div className={styles['swiper-about-us__name-wrapper']}>
                <p className={styles['swiper-about-us__name']}>{title}</p>
                <p className={styles['swiper-about-us__description']}>{description}</p>
            </div>
        </div>
    )
}

export default SwiperAboutUs