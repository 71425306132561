import { Navbar ,Container} from 'react-bootstrap'
import styles from './NavBar.module.css'
import './Navbar.css'
import { useTranslation } from 'react-i18next'
import tulparLogo from 'assets/imgs/logo-pyramids.png'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { useState } from 'react'
import { HashLink } from 'react-router-hash-link'
type ComponentPropsType ={
    isPageScrolling:boolean,
    isSideMenuShown:boolean,
    setIsSideMenuShown:any,
}
function NavBar(props:ComponentPropsType) {
    const { isPageScrolling ,isSideMenuShown,setIsSideMenuShown } =props
    const {t} =useTranslation()
    
    return (
        <Navbar expand="xl" className={`${styles['navbar']} ${isPageScrolling?styles['navbar--scrolling']:''}
        ${isSideMenuShown?styles['navbar--scrolling']:''}`}>
            <Container className={styles['navbar__nav__container']}>
                <ul className={styles['navbar__nav-list']}>
                    <li className={styles['navbar__nav-logo-item']}>
                        <Link to='/' className={styles['navbar__nav-logo-link']}>
                            <img src={tulparLogo} className={styles['navbar__nav-logo']}/>
                        </Link>
                    </li>
                    <li>
                        <NavLink to='/' className={`${styles['navbar__nav-link']} navbar__nav-link`}>
                            <span className={styles['navbar__nav-link-text']}>{t('Home')}</span>
                        </NavLink>
                    </li>
                    <li>
                        <HashLink to='/#about-us' className={`${styles['navbar__nav-link']} navbar__nav-link`}>
                            <span className={styles['navbar__nav-link-text']}>{t('About Us')}</span>
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to='/#contact-us' className={`${styles['navbar__nav-link']} navbar__nav-link`}>
                            <span className={styles['navbar__nav-link-text']}>{t('Contact Us')}</span>
                        </HashLink>
                    </li>
                    {/* <li>
                        <NavLink to='/blogs' className={`${styles['navbar__nav-link']} navbar__nav-link`}>
                            <span className={styles['navbar__nav-link-text']}>{t('Blogs')}</span>
                        </NavLink>
                    </li> */}
                    <li>
                        <button type='button' onClick={()=>setIsSideMenuShown((prevVal:boolean)=>!prevVal)}
                        className={`${styles['navbar__nav-menu']} ${isSideMenuShown?styles['navbar__nav-menu--active']:''}`}>
                            <span className={styles['navbar__nav-menu-span']}></span>
                            <span className={styles['navbar__nav-menu-span']}></span>
                            <span className={styles['navbar__nav-menu-span']}></span>
                        </button>
                    </li>
                </ul>
            </Container>
        </Navbar>
    )
}

export default NavBar