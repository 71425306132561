import React,{ useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import { ToastContainer } from 'react-toastify';
// import NavBar from 'components/Global/Layout/NavBar/NavBar';
// import SideBar from 'components/Global/Layout/SideBar/SideBar';
import ErrorPage from './views/ErrorPage/ErrorPage';
import Home from './views/Home/Home';
import { axiosConfig } from 'utils/axiosConfig';
// import Loading from 'components/Global/Elements/Loading/Loading';
import { useReducer } from 'react';
// import { changeAllCarsAction, changeBrandAction, changeGeneralSettingsAction, changeTypeAction } from 'reduxStore/Global/GlobalActions';
import { useDispatch, useSelector } from 'react-redux';
import NavBar from 'components/Global/Layout/NavBar/NavBar';
import AOS from "aos";
import "aos/dist/aos.css";
import Blogs from 'views/Blogs/Blogs';
import Blog from 'views/Blog/Blog';
import SideBar from 'components/Global/Layout/SideBar/SideBar';
function AnimatedRoutes() {
    const [isPageScrolling , setIsPageScrolling] =useState(false)
    const [isRouteExist,setIsRouteExist] =useState(false)
    const [isLoadingVisible,setIsLoadingVisible]=useState(false)
    const [isSideMenuShown,setIsSideMenuShown] =useState<boolean>(false)


    const dispatch= useDispatch()
    const location = useLocation()
    useEffect(()=>{
      window.scrollTo(0,0)
  },[location])
  useEffect(() => {
    // AOS.init({ duration: 400,once: true ,offset: 20});
    AOS.init({ duration: 400,once: true ,offset: 20});
    AOS.refresh();
  }, []);
  useEffect(()=>{
    let currentScroll = 0
    let scrollEvent = (e:any)=>{
      if(window.scrollY > currentScroll+100){
        setIsPageScrolling(true)
      }else{
        setIsPageScrolling(false)
      }
      console.log('eeessee',e.button)
      // console.log('eeeee',window.scrollY)
    }
    window.addEventListener('scroll',scrollEvent)
    return ()=> window.removeEventListener('scroll',scrollEvent)
  },[])
  return (
    <>
      {/* {isLoadingVisible&&<Loading/>} */}
      <div className="App">
        <ToastContainer />
        {/* <BottomNavBar/> */}
        {/* <BottomNavBarLinks/> */}
        <NavBar isPageScrolling={isPageScrolling} isSideMenuShown={isSideMenuShown} setIsSideMenuShown={setIsSideMenuShown}/>
        <SideBar isSideMenuShown={isSideMenuShown} setIsSideMenuShown={setIsSideMenuShown}/>
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path='/' element={<Home/>}></Route>
                <Route path='/blogs' element={<Blogs/>}></Route>
                <Route path='/blog' element={<Blog/>}></Route>
                <Route path='*' element={<ErrorPage/>}></Route>
            </Routes>
      </AnimatePresence>
        </div>
    </>
  )
}

export default AnimatedRoutes