import styles from './SwiperHeader.module.css'
import { useTranslation } from 'react-i18next'
import blogCardImage from 'assets/imgs/blogCardImage.jpg'
import HeaderBgVideo1 from 'assets/videos/HeaderBgVideo1.mp4'
import {ReactComponent as LeftArrowTall} from 'assets/icons/leftArrowTall.svg'
import {ReactComponent as RightArrowTall} from 'assets/icons/rightArrowTall.svg'
import ReactPlayer from 'react-player'
// import HeaderBgVideo1 from 'assets/videos/HeaderBgVideo1.mp4'
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { SwiperHeaderBGStyled } from './SwiperHeaderBGStyled'
type ComponentCustomPropsTypes={
    title:string,
    subTitle:string,
    videoSrc:string,
    bgSrc:string,
    sliderNumber:string,
}
function SwiperHeader(props:ComponentCustomPropsTypes) {
    const {title ,subTitle,videoSrc,bgSrc,sliderNumber}= props
    const {t} =useTranslation()
    
    return (
        <div className={styles['swiper-header']}>
            {/* <div className={styles['swiper-header--bg']}></div> */}
            <SwiperHeaderBGStyled bgSrc={bgSrc}></SwiperHeaderBGStyled>
            <Container>
                <div className={styles['swiper-header__video-wrapper']}>
                    {/* <ReactPlayer 
                    url={videoSrc} 
                    controls={false}
                    playing={true}
                    loop={true}
                    muted ={true}
                    width="100%"
                    height="70vh"
                    className={styles['swiper-header__video']}
                    config={{
                        youtube: {
                            playerVars: { showinfo: 1 }
                        },
                        facebook: {
                            appId: '12345'
                        }
                    }}
                    /> */}
                    <div style={{background:`url(${bgSrc})` , width:'100%', height:'70vh', backgroundPosition: '100%'}} className={styles['swiper-header__video']}></div>
                    {/* <div className={styles['swiper-header__video-content-wrapper']}>
                        <div className={styles['swiper-header__video-content-title-wrapper']}>
                            <h2 className={styles['swiper-header__video-content-welcome']}>Welcome</h2>
                            <h1 className={styles['swiper-header__video-content-title']}>Lorem Ipsum is simply dummy text of the printing and typesetting</h1>
                            <p className={styles['swiper-header__video-content-description']}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled</p>
                        </div>
                    </div> */}
                </div>
                <div className={styles['swiper-header__bottom-wrapper']}>
                    <div className={styles['swiper-header__info-wrapper']}>
                        <p className={styles['swiper-header__info-number']}>{sliderNumber}</p>
                        <div className={styles['swiper-header__info-title-wrapper']}>
                            <p className={styles['swiper-header__info-title-header']}>{subTitle}</p>
                            <p className={styles['swiper-header__info-title']}>{title}</p>
                        </div>
                    </div>
                    {/* <button className={styles['swiper-header__view-film']}>
                        <span className={styles['swiper-header__view-film-text']}>
                            {t('View Film')}
                            <RightArrowTall className={styles['swiper-header__view-film-icon']}/>
                        </span>
                    </button> */}
                </div>
            </Container>
        </div>
    )
}

export default SwiperHeader