import styles from './SectionTitle.module.css'
import { useTranslation } from 'react-i18next'
type ComponentCustomPropsTypes={
    title?:string
}
function SectionTitle(props:ComponentCustomPropsTypes) {
    const {title}= props
    const {t} =useTranslation()
    
    return (
        <div className={styles['section-title']}>{title}</div>
    )
}

export default SectionTitle