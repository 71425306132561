import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useState } from 'react'
import styles from './AboutUs.module.css'
import Footer from 'components/Global/Layout/Footer/Footer'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import { Col, Container, Row } from 'react-bootstrap'
import aboutUsImage from 'assets/imgs/aboutUs.png'
import {ReactComponent as ContIcon} from 'assets/icons/contIcon.svg'
import {ReactComponent as CommercialIcon} from 'assets/icons/CommercialIcon.svg'
import {ReactComponent as SupplyIcon} from 'assets/icons/supplyIcon.svg'
import {ReactComponent as GraphicDesignIcon} from 'assets/icons/graphicDesignIcon.svg'
import {ReactComponent as ProjectmanagemenIcon} from 'assets/icons/ProjectmanagemenIcon.svg'
import {ReactComponent as InfrastructureIcon} from 'assets/icons/InfrastructureIcon.svg'
import { emailConnect } from 'utils/features'
import SectionTitle from 'components/Global/Elements/SectionTitle/SectionTitle'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import SwiperAboutUs from 'components/Global/Elements/SwiperAboutUs/SwiperAboutUs'

import aboutUsImage1 from 'assets/imgs/aboutUsImg1.png'
import aboutUsImage2 from 'assets/imgs/aboutUsImg2.png'
import aboutUsImage3 from 'assets/imgs/aboutUsImg3.png'

import person1 from 'assets/imgs/person1.jpg'
import person2 from 'assets/imgs/person2.jpeg'
import person3 from 'assets/imgs/person3.jpg'

export type TeamMemberType ={
  image:string,
  title:string,
  description:string,
}
// export TeamMemberType =TeamMemberType
function AboutUs() {
  const {t} =useTranslation()
  const [teamMembers,setTeamMembers] =useState<TeamMemberType[]>([
    {image:person1,title:'Mohamemd Ghazala',description:'Co-founder / CEO'},
    {image:person2,title:'Dia Magady',description:'Co-founder'},
    {image:person3,title:'Fathia Mohamed',description:'Co-founder'},
  ])
  const [isPageLoading,setIsPageLoading]=useState(true)
  return (
    <>
      <section className={styles['about-us']} id='about-us'>
        <SectionTitle title={`${t('ABOUT US')}`}/>
        <Container>
          <Row>
            <Col lg='7'>
              <div className={styles['about-us__content-wrapper']}>
                {/* <h1 className={styles['about-us__sub-title']}>{t('Hello & Welcome')}</h1> */}
                <h1 className={styles['about-us__title']}>{t('Pyramids General Contracting & Public Supply – Building the Future with Excellence.')}</h1>
                <p className={styles['about-us__description']}>
                  Pyramids is a leader in the fields of general contracting and public supply. Our mission is to provide high-standard construction services and deliver public supply solutions that meet the unique needs of every client. With an unwavering focus on quality and efficiency, we help build strong foundations for businesses, communities, and public services.
                </p>
                <div className={styles['about-us__features-wrapper']}>
                  <div className={styles['about-us__feature-wrapper']}>
                    <ContIcon className={styles['about-us__feature-icon']}/>
                    <span className={styles['about-us__feature-text']}>1- {t('General Contracting Services')}</span>
                  </div>
                  <div className={styles['about-us__feature-wrapper']}>
                    <SupplyIcon className={styles['about-us__feature-icon']}/>
                    <span className={styles['about-us__feature-text']}>2- {t('Public Supply Solutions')}</span>
                  </div>
                  <div className={styles['about-us__feature-wrapper']}>
                    <InfrastructureIcon className={styles['about-us__feature-icon']}/>
                    <span className={styles['about-us__feature-text']}>3- {t('Infrastructure Development')}</span>
                  </div>
                  <div className={styles['about-us__feature-wrapper']}>
                    <CommercialIcon className={styles['about-us__feature-icon']}/>
                    <span className={styles['about-us__feature-text']}>4- {t('Commercial & Residential Construction')}</span>
                  </div>
                  <div className={styles['about-us__feature-wrapper']}>
                    <SupplyIcon className={styles['about-us__feature-icon']}/>
                    <span className={styles['about-us__feature-text']}>5- {t('Supply Chain Management')}</span>
                  </div>
                  <div className={styles['about-us__feature-wrapper']}>
                    <ProjectmanagemenIcon className={styles['about-us__feature-icon']}/>
                    <span className={styles['about-us__feature-text']}>6- {t('Project Management & Consulting')}</span>
                  </div>
                </div>
                <div className={styles['about-us__team-wrapper']}>
                  <Swiper
                    spaceBetween={30}
                    slidesPerView={3}
                    cssMode= {true}
                    // ref={swiperRef}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },
                        
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay,  Navigation]}
                    className="mySwiper"
                  >
                    {
                      teamMembers && teamMembers?.map((member:TeamMemberType,index:number)=>(
                        <SwiperSlide key={index}>
                          <SwiperAboutUs 
                          image={member?.image}
                          title={member?.title}
                          description={member?.description}/>
                        </SwiperSlide>
                      ))
                    }
                  </Swiper>
                </div>
              </div>

            </Col>
            <Col lg='5'>
              <img src={aboutUsImage} alt='about us' className={styles['about-us__img']}/>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default AboutUs