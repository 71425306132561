import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useState } from 'react'
import styles from './Blogs.module.css'
import Footer from 'components/Global/Layout/Footer/Footer'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import { Container } from 'react-bootstrap'
import BlogCard from 'components/Global/Elements/Cards/BlogCard/BlogCard'
function Blogs() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <section className={styles['blogs']}>
          <Container>
          <h1 className={styles['blogs__title']}>{t('Blogs')}</h1>
            <div className={styles['blogs__wrapper']}>
              <BlogCard/>
              <BlogCard/>
              <BlogCard/>
              <BlogCard/>
              <BlogCard/>
              <BlogCard/>
              <BlogCard/>
            </div>
          </Container>
        </section>
        {/* <Footer/> */}
    </motion.div>
    </>
  )
}

export default Blogs